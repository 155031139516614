import { LRUCache } from "lru-cache";
import { useCookie } from "#app";

// const { baseURL } = useRuntimeConfig();
// console.log("Runtime config:", config.public.baseURL);

const cacheOptions = {
  max: 500,
  maxAge: 1000 * 60 * 5,
};
const cache = new LRUCache(cacheOptions);

const apiBaseUrl = "https://api.fiyatavantaji.com/";

export const fetchHeaderCategoryList = async () => {
  try {
    const response = await $fetch(`/api/fcategories`);
    return response.categories;

    // cache.set(categoryList.toString(), categoryList);
    // return Promise.resolve(categoryList);
  } catch (error) {
    console.error("Error fetching data:", error);
    return false;
  }
};

export const fetchHomeProductQuery = async () => {
  // console.log("queryProductDetail = ", productId);
  try {
    const token = useCookie("token").value;
    console.log("token", token);

    const data = await $fetch(`/api/hmp`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`, // Token'ı istek başlığına ekliyoruz
          }
        : {},
    });

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return false;
  }
};

export const fetchSearchQuery = async (params: any) => {
  // Token'ı alın
  const token = useCookie("token").value;

  let apiPrefix = "/api";

  // Eğer token varsa, tarih bilgisini params içine ekleyin
  if (token) {
    apiPrefix = "https://api.fiyatavantaji.com/api";
    params.date = new Date().toString();
  }

  const queryParams = new URLSearchParams(params);
  console.log("queryParams", queryParams);
  try {
    const data = await $fetch(`${apiPrefix}/search?${queryParams}`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`, // Token'ı istek başlığına ekliyoruz
          }
        : {},
    });

    // const data = await fetch(
    //   `${apiBaseUrl}/search?${queryParams}`,
    //   { mode: "no-cors" }
    // );

    const jsonData = await data;

    if (params.pageType == "popular") {
      return jsonData;
    } else if (params.pageType == "filter") {
      return jsonData;
    } else if (params.pageType == "searchbar") {
      return jsonData;
    } else {
      return jsonData.items;
    }
  } catch (error) {
    // console.error("Error fetching data:", error);
    return false;
  }
};

export const queryProductDetail = async (productId: any) => {
  try {
    const token = useCookie("token").value;
    console.log("token", token);

    const data = await $fetch(`/api/product/${productId}`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    });

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return false;
  }
};

export const queryCategoryDetail = async (categoryId: any) => {
  // console.log("queryProductDetail = ", productId);
  try {
    const data = await $fetch(`/api/category/${categoryId}`);

    return data;
  } catch (error) {
    // console.error("Error fetching data:", error);
    return false;
  }
};

export const queryDiscountRandomProduct = async (randomKey: any) => {
  // console.log("queryProductDetail = ", productId);
  try {
    const data = await fetch(`/api/randomProduct/${randomKey}`);
    const jsonData = await data.json();

    // console.log("queryDiscountRandomProduct jsonData ", jsonData);

    // cache.set(categoryId.toString(), jsonData);
    // return Promise.resolve(jsonData);
    return jsonData;
  } catch (error) {
    // console.error("Error fetching data:", error);
    return false;
  }
};
